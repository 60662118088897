import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from 'src/components/button/Button.module.scss';

const Button = ({ link, full, theme }) =>
  link.disabled ? (
    <div
      className={classNames(styles.button, styles.button_disabled, {
        [styles.button_full]: full,
      })}
    >
      {link.label}
    </div>
  ) : (
    <a
      className={classNames(styles.button, {
        [styles.button_full]: full,
        [styles[`button_${theme}`]]: theme,
      })}
      href={link.link.url}
      target={link.link.target}
      rel="noopener noreferrer"
    >
      {link.link.title}
    </a>
  );

Button.propTypes = {
  link: PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.string,
    link: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    target: PropTypes.string,
  }).isRequired,
  full: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']).isRequired,
};

Button.defaultProps = {
  full: false,
};

export default Button;
