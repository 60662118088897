import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { useAppContext } from 'src/context/AppContext';

import Button from 'src/components/button/Button';
import Heading from 'src/components/content/heading/Heading';
import Image from 'src/components/image/Image';
import Paragraph from 'src/components/content/paragraph/Paragraph';

import styles from 'src/containers/sample/SampleContainer.module.scss';

const SampleContainer = ({
  description,
  images,
  isLast,
  links,
  subtitle,
  title,
}) => {
  const appContext = useAppContext();
  const isMobile = appContext.breakpoint === 'sm';
  const primaryImage = images.find((x) => x.primary === true);
  const primaryImageIndex = images.indexOf(primaryImage);

  return (
    <>
      <div className={styles.sample}>
        <div className={styles.sample__image}>
          {images.length === 1 && (
            <Image
              imageData={images[0].image.localFile}
              altText={`${images[0].image.title} screenshot`}
            />
          )}
          {images.length > 1 && isMobile && (
            <Image
              imageData={images[primaryImageIndex].image.localFile}
              altText={`${images[primaryImageIndex].image.title} screenshot`}
            />
          )}
          {images.length > 1 && !isMobile && (
            <div className={styles.sample__imageGrid}>
              {images.map((image) => (
                <div className={styles.sample__imageGridImage} key={uuidv4()}>
                  <Image
                    imageData={image.image.localFile}
                    altText={`${title} screenshot`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.sample__details}>
          <div className={styles.sample__header}>
            <Heading
              content={title}
              priority={1}
              size={2}
              theme="lightest"
              wrap
            />
            <Heading
              content={subtitle}
              priority={2}
              size={6}
              theme="light"
              variant="subheading"
              wrap
            />
          </div>
          <div className={styles.sample__description}>
            <Paragraph
              content={description}
              size={2}
              theme="lightest"
              weight="thin"
            />
          </div>
          <div className={styles.sample__link}>
            {links &&
              links.map((link) => (
                <Button key={uuidv4()} link={link} full theme="light" />
              ))}
          </div>
        </div>
      </div>
      {!isLast && <div className={styles.sample__splitter} />}
    </>
  );
};

SampleContainer.propTypes = {
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  isLast: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

SampleContainer.defaultProps = {
  isLast: false,
};

export default SampleContainer;
