import React from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import AppLayout from 'src/layouts/app/AppLayout';
import SampleContainer from 'src/containers/sample/SampleContainer';

const Work = ({ data }) => (
  <AppLayout theme="dark">
    {data.wpPage.fieldsSamples.samples.map((sample, index) => (
      <SampleContainer
        key={uuidv4()}
        images={sample.sample.images}
        description={sample.sample.description}
        subtitle={sample.sample.subtitle}
        title={sample.sample.title}
        links={sample.sample.links}
        isLast={index === data.wpPage.fieldsSamples.samples.length - 1}
      />
    ))}
  </AppLayout>
);

Work.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      fieldsSamples: PropTypes.shape({
        samples: PropTypes.arrayOf({
          sample: PropTypes.shape({
            description: PropTypes.string.isRequired,
            subtitle: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
          }),
        }),
      }),
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query WorkQuery {
    wpPage(slug: { eq: "work" }) {
      fieldsSamples {
        samples {
          sample {
            description
            title
            subtitle
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              primary
            }
            links {
              disabled
              label
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default Work;
